import { faCheck, faMinus, faPlus, faStar, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select'
import { getLatestCameraReading } from "../../js/utils/lastCameraReading"
import '../../styles/farms_overview.css'
import { ConfigState } from '../redux/slices/config_slice'
import { CameraInfo, FarmData, FarmEidTypes, FarmStatuses, MobilityThresholds } from '../redux/types'
import { getFlagByName } from '../utils/farmFlags'
import searchFarmData from '../utils/searchFarmData'
import { sortFarmDataByCol } from '../utils/sortFarmDataByCol'
import CameraRow from './camera_row'
import FarmNotes from './farm_notes'
import MoreFarmInfo from './more_farm_info'
import SortColIcon from './sort_col_icon'
// import { fchmod } from 'fs'
// import { render } from '@testing-library/react'
export const HDD_ERROR_THRESHOLD = 90

interface Props {
  farmData: { [key: string]: FarmData }
  farmStatuses: FarmStatuses
  farmEidTypes: FarmEidTypes
  farmIssues: any
  setFarmStatus: Function
  submitFarmIp: (farmId: string, ip: string) => void
  mobilityThresholdsKeyPressed: (
    farmId: string,
    mobilityThresholds: MobilityThresholds
  ) => void
  mobilityThresholdOneKeyPressed: (
    farmId: string,
    mobilityThresholdOne: number
  ) => void
  mobilityThresholdTwoKeyPressed: (
    farmId: string,
    mobilityThresholdTwo: number
  ) => void
  mobilityThresholdThreeKeyPressed: (
    farmId: string,
    mobilityThresholdThree: number
  ) => void
  trimLenKeyPressed: (farmId: string, trimLen: number) => void
  submitCameraIp: (cameraId: string, farmId: string, ip: string) => void
  deleteCamera: (farmId: string, cameraId: string) => void
  // Function type from react-select types
  updateFarmIssues: (
    value: ValueType<OptionTypeBase>,
    action: ActionMeta
  ) => void
  configData: ConfigState | undefined
  setFarmEidType: Function
  addNote: (farmId: string, text: string) => void
  deleteNote: (farmId: string, noteId: string) => void
  cameraIpLoading: boolean
  farmIpLoading: boolean
  trimLenLoading: boolean
  submitTrimLen: (farmId: string, trimLen: number) => void
  addToFavourites: (farmId: string) => void
  removeFromFavourites: (farmId: string) => void
  isRoot: boolean,
}

interface State {
  expandedRows: Array<any>
  selectedCol: string
  sortDirection: string
  search: string
  onlyFavourites: boolean
}

// function LiveFarmsOverview(props: Props) {
class LiveFarmsOverview extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      expandedRows: [],
      selectedCol: '',
      sortDirection: '',
      search: '',
      onlyFavourites: false,
    }
  }

  handleRowClick(rowId: any) {
    const currentExpandedRows = this.state.expandedRows
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)

    const newExpandedRows = isRowCurrentlyExpanded ?
      currentExpandedRows.filter(id => id !== rowId) :
      currentExpandedRows.concat(rowId)

    this.setState({ expandedRows: newExpandedRows })
  }

  unmatchedNotUndefined(unmatched: number | undefined) {
    if (unmatched == undefined)
      return 0
    else
      return unmatched
  }

  handleColumnClick(columnName: string) {
    let newSortDir: string
    // Loops direction when a column is clicked again: none -> asc -> desc
    switch (this.state.sortDirection) {
      case '':
        newSortDir = 'asc'
        break
      case 'asc':
        newSortDir = 'desc'
        break
      case 'desc':
        newSortDir = ''
        break
      default:
        newSortDir = ''
        break
    }

    // When a column is clicked for the first we sort it by ascending
    if (columnName !== this.state.selectedCol) {
      newSortDir = 'asc'
    }

    this.setState({ selectedCol: columnName, sortDirection: newSortDir })
  }

  addToFavouritesClicked(farmId: string) {
    this.props.addToFavourites(farmId)
  }

  removeFromFavouritesClicked(farmId: string) {
    this.props.removeFromFavourites(farmId)
  }

  renderItem(item: FarmData) {
    const clickCallback = () => this.handleRowClick(item.id)
    const { configData, farmEidTypes, farmIssues, farmStatuses } = this.props

    // console.log('renterItem farmIssues == ', farmIssues)
    // console.log('item.farm.issues == ', item.farm.issues)
    // console.log('item.farm.flags == ', item.farm.flags)
    // const beefFlag = getFlagByName('beef', item.farm.flags)
    let beefFlag = undefined
    if (item.farm.flags) {
      beefFlag = getFlagByName('beef', item.farm.flags)
    }
    let reactSelectOptions = []
    if (farmIssues.selectOptions && item.farm.issues) {
      reactSelectOptions = farmIssues.selectOptions.filter((issue: any) => {
        return item.farm.issues?.includes(issue.value)
      })
    }

    let hddFilling = false // Set to true if HDD is getting full

    if (item && item.farm && item.farm.cameras) {
      for (let i = 0; i < item.farm?.cameras?.length; i++) {
        // @ts-ignore
        if (item.farm.cameras[i].diskPercent && item.farm.cameras[i].diskPercent > HDD_ERROR_THRESHOLD)
          hddFilling = true
      }
    }

    const vpnIssue = item.farm.cameras?.find(
      (cam) => !cam.networkStatus?.vpnConnected
    )
    const apiIssue = item.farm.cameras?.find(
      (cam) => !cam.networkStatus?.apiConnected
    )
    const farmHasError =
      item.farm.issues?.length !== 0 ||
      vpnIssue ||
      apiIssue ||
      hddFilling ||
      (item.cowReadings24Hr === 0 && item.beefTwentyFourHourCowCount === 0)

    const matchedRatio = item.cowsMatched24Hr / Math.max(item.cowCount, 1)
    const matchedPercent = Math.round(matchedRatio * 100) + "%"

    const isFavourite = configData?.favouriteFarms.includes(item.farm._id)

    const itemRows = [
      <tr
        // style={
        // item.farm.issues?.length !== 0 ||
        //   !networkIssue ||
        //   hddFilling ||
        //   item.bcsAlarmsToday === 0
        //   /*|| item.bcsAlarmsToday <= item.cowCount*/
        //   ? { backgroundColor: 'red' }
        //   : {}}
        key={item.farm._id}
      >
        <td
          style={{ padding: configData?.compactMode ? 0 : "8px" }}
          onClick={clickCallback}
        >
          {this.state.expandedRows.includes(item.id) && (
            <FontAwesomeIcon icon={faMinus} />
          )}
          {!this.state.expandedRows.includes(item.id) && (
            <FontAwesomeIcon icon={faPlus} />
          )}
        </td>
        <td
          style={{
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor: farmHasError === true ? "#f00" : "",
          }}
        >
          {item.farm.name}
          {isFavourite &&
            <FontAwesomeIcon icon={faStar} style={{ color: '#b59410', float: 'right' }} />
          }
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item.farm.cameras?.length}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item?.lastPing?.prodigyVersion}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item?.lastPing?.socpVersion}
        </td>
        {this.props.configData?.showHidden && (
          <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
            {item.farm._id}
          </td>
        )}
        {this.props.configData?.showHidden && (
          <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
            <input type="text" name="test" defaultValue={item.farm.ip} />
          </td>
        )}
        {this.props.configData?.showHidden && (
          <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
            <select onChange={(e) => this.props.setFarmEidType(e.target.value)}>
              {farmEidTypes &&
                farmEidTypes.selectOptions.map((eidType: any) => {
                  return (
                    <option
                      key={eidType.value}
                      value={item.farm._id + "|" + eidType.value}
                      selected={item.farm.eidType === eidType.value}
                    >
                      {eidType.label}
                    </option>
                  )
                })}
              {!farmEidTypes && <option>Loading....</option>}
            </select>
          </td>
        )}
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item.cowCount}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item.cowReadings24Hr ? item.cowReadings24Hr : '0'}
        </td>
        <td
          style={{
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor:
              item.cowReadings24Hr === 0 &&
                beefFlag !== undefined &&
                beefFlag === false
                ? "#f00"
                : "",
          }}
        >
          {item.cowsMatched24Hr}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item.cowsUnmatched24Hr}
        </td>
        <td
          style={{
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor:
              item.beefTwentyFourHourCowCount === 0 &&
                beefFlag !== undefined &&
                beefFlag === true
                ? "#f00"
                : "",
          }}
        >
          {item.beefTwentyFourHourCowCount}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {matchedPercent}
        </td>
        {/* <td>{item.lastPing?.diskPercent}%</td> */}
        <td
          style={{
            textAlign: "center",
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor: hddFilling === true ? "#f00" : "",
          }}
        >
          {hddFilling === false && <FontAwesomeIcon icon={faCheck} size="2x" />}
          {hddFilling === true && <FontAwesomeIcon icon={faTimes} size="2x" />}
        </td>
        <td
          style={{
            textAlign: "center",
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor: vpnIssue ? "#f00" : "",
          }}
        >
          {vpnIssue ? (
            <FontAwesomeIcon icon={faTimes} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faCheck} size="2x" />
          )}
        </td>
        <td
          style={{
            textAlign: "center",
            padding: configData?.compactMode ? 0 : "8px",
            backgroundColor: apiIssue ? "#f00" : "",
          }}
        >
          {apiIssue ? (
            <FontAwesomeIcon icon={faTimes} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faCheck} size="2x" />
          )}
        </td>
        <td
          style={{
            textAlign: "center",
            padding: configData?.compactMode ? 0 : "8px",
          }}
        >
          {item.lastPing && item.lastPing.timestamp
            ? moment.utc(item.lastPing?.timestamp).local().format("DD/MM/YY")
            : ""}
          <br />
          {item.lastPing && item.lastPing.timestamp
            ? moment.utc(item.lastPing?.timestamp).local().format("HH:mm:ss")
            : ""}
        </td>
        <td
          style={{
            textAlign: "center",
            padding: configData?.compactMode ? 0 : "8px",
          }}
        >
          {item.farm.cameras && getLatestCameraReading(item.farm.cameras)
            ? moment
              .utc(getLatestCameraReading(item.farm.cameras))
              .local()
              .format("DD/MM/YY")
            : ""}
          <br />
          {item.farm.cameras && getLatestCameraReading(item.farm.cameras)
            ? moment
              .utc(getLatestCameraReading(item.farm.cameras))
              .local()
              .format("HH:mm:ss")
            : ""}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          {item.farm.activeAlarms}
        </td>
        <td style={{ padding: configData?.compactMode ? 0 : "8px" }}>
          <select onChange={(e) => this.props.setFarmStatus(e.target.value)}>
            {farmStatuses &&
              farmStatuses.selectOptions.map((status: any) => {
                return (
                  <option
                    key={status.value}
                    value={item.farm._id + "|" + status.value}
                    selected={item.farm.status === status.value}
                  >
                    {status.label}
                  </option>
                )
              })}
            {!farmStatuses && <option>Loading....</option>}
          </select>
        </td>
      </tr>,
    ]

    if (this.state.expandedRows.includes(item.id)) {
      const { importHistory } = item.farm
      // console.log('Import history == ', importHistory)
      // @ts-ignore
      // console.log('Sorted History == ', new Date(importHistory?.slice().sort((a, b) =>
      //   new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())[0].timestamp).toISOString())
      // moment.utc(camera.lastPing).local().format('DD/MM/YY')
      itemRows.push(
        <tr
          style={
            {
              /*height: '100px'*/
            }
          }
          key={"row-expanded-" + item.id}
        >
          <td colSpan={18}>
            {isFavourite
              ?
              <p
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.removeFromFavouritesClicked(item.farm._id)}
              >
                Remove from favourites
              </p>
              :
              !this.props.isRoot &&
              <p
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => this.addToFavouritesClicked(item.farm._id)}
              >
                Add to favourites
              </p>
            }
            <h3>
              Last Import:{" "}
              {item.lastImportDate
                ? moment
                  .utc(item.lastImportDate)
                  .format("Do MMMM YYYY \\a\\t HH:ss a")
                : "No import recorded"}
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Farm ID</th>
                  <th>IP</th>
                  <th>EID Type</th>
                  {/*<th>Threshold 1</th>
                  <th>Threshold 2</th>
                 <th>Threshold 3</th>*/}
                  <th>Trim Len</th>
                  <th>Left Edge</th>
                </tr>
              </thead>
              <tbody>
                <MoreFarmInfo
                  farm={item.farm}
                  farmEidTypes={farmEidTypes}
                  ipLoading={this.props.farmIpLoading}
                  submitIp={(farmId: string, ip: string) =>
                    this.props.submitFarmIp(farmId, ip)
                  }
                  setFarmEidType={(type: string) =>
                    this.props.setFarmEidType(type)
                  }
                  trimLenLoading={this.props.trimLenLoading}
                  submitTrimLen={(farmId: string, trimLen: number) =>
                    this.props.submitTrimLen(farmId, trimLen)
                  }
                />
              </tbody>
            </table>
            {item.farm.cameras && item.farm.cameras.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Camera ID</th>
                    <th>IP</th>
                    <th>HDD</th>
                    <th>Temp</th>
                    <th>CPU</th>
                    <th>RAM</th>
                    <th>
                      Ping
                      <br />
                      Delay
                    </th>
                    <th>Prod Ver</th>
                    <th>CV Ver</th>
                    <th>Last Ping</th>
                    <th>Last Reading</th>
                    <th>Last EID Recieve</th>
                    <th>24Hr EID Count</th>
                    <th>Depth Noise</th>
                    <th>Connect</th>
                  </tr>
                </thead>
                <tbody>
                  {item.farm.cameras?.map((camera: CameraInfo) => (
                    <CameraRow
                      camera={camera}
                      ipLoading={this.props.cameraIpLoading}
                      farmId={item.farm._id}
                      submitIp={(farmId, cameraId, ip) =>
                        this.props.submitCameraIp(cameraId, farmId, ip)
                      }
                      deleteCamera={(farmId, cameraId) =>
                        this.props.deleteCamera(farmId, cameraId)
                      }
                    />
                  ))}
                </tbody>
              </table>
            )}
            <FarmNotes
              notes={item.farm.notes}
              saveNote={(text: string) =>
                this.props.addNote(item.farm._id, text)
              }
              deleteNote={(noteId: string) =>
                this.props.deleteNote(item.farm._id, noteId)
              }
            />
          </td>
        </tr>
      )
    }

    return itemRows
  }

  render() {
    const { farmEidTypes, farmData, farmIssues, farmStatuses } = this.props

    const errorArray: Array<FarmData> = []
    const nonErrorArray: Array<FarmData> = []
    const favourites: Array<FarmData> = []
    let sortedFarmData: Array<FarmData> = []

    if (farmIssues && farmIssues.selectOptions) {
      // console.log('farmData == ', farmData)
      if (farmData !== {}) {
        Object.entries(farmData).map(([key, fd]: [any, FarmData]) => {
          // console.log('fd.farms.flags == ', fd.farm.flags)
          // @ts-ignore
          // console.log(fd.farm.name + ' - getFlagByName(\'hideOnMonitor\', fd.farm.flags) == ', getFlagByName('hideOnMonitor', fd.farm.flags))
          if (fd.farm.name !== 'TEST FARM' &&
            (fd.farm.flags && getFlagByName('hideOnMonitor', fd.farm.flags)) !== true
            // (fd.farm.flags && fd.farm.flags.length > 0 &&
            //   getFlagByName('hideOnMonitor', fd.farm.flags) !== false)// &&
            // (fd.farm.flags &&
            //   getFlagByName('hideOnMonitor', fd.farm.flags) === undefined))
          ) {
            const newFd = Object.assign({}, fd, { id: fd.farm._id })

            let hddFilling = false // Set to true if HDD is getting full

            if (newFd.farm.cameras) {
              for (let i = 0; i < newFd.farm?.cameras?.length; i++) {
                // @ts-ignore
                if (newFd.farm.cameras[i].diskPercent && newFd.farm.cameras[i].diskPercent > HDD_ERROR_THRESHOLD)
                  hddFilling = true
              }
            }

            const isAllowedStatus =
              fd.farm.status === "cameraAndEidConnected" ||
              fd.farm.status === "installedWithIssue"
            const hasEidIssue = fd.farm.issues?.includes("noeid")
            const vpnIssue = newFd.farm.cameras?.find(
              (cam) => !cam.networkStatus?.vpnConnected
            )
            const apiIssue = newFd.farm.cameras?.find(
              (cam) => !cam.networkStatus?.apiConnected
            )

            const farmHasError =
              newFd.farm.issues?.length !== 0 ||
              vpnIssue ||
              apiIssue ||
              hddFilling ||
              (newFd.cowReadings24Hr === 0 &&
                newFd.beefTwentyFourHourCowCount === 0)

            const isFavourite = this.props.configData?.favouriteFarms.includes(newFd.farm._id)

            if (!hasEidIssue && isAllowedStatus) {
              if (farmHasError) {
                errorArray.push(newFd)
              } else {
                nonErrorArray.push(newFd)
              }
            }

            if (this.state.onlyFavourites && isFavourite) {
              favourites.push(newFd)
            }
          }
        })
      }

      if (this.state.onlyFavourites) {
        sortedFarmData = [...favourites]
      } else {
        sortedFarmData = [...errorArray, ...nonErrorArray]
      }


      if (this.state.selectedCol && this.state.sortDirection) {
        sortedFarmData = sortFarmDataByCol(sortedFarmData, this.state.selectedCol, this.state.sortDirection)
      }

      if (this.state.search) {
        sortedFarmData = searchFarmData(sortedFarmData, this.state.search)
      }
      console.log('sortedFarmData == ', sortedFarmData)

      let allItemRows: Array<any> = []

      sortedFarmData.forEach(item => {
        const perItemRows = this.renderItem(item)
        allItemRows = allItemRows.concat(perItemRows)
      })

      return (
        <div>
          <div style={{ position: 'absolute', right: '0', top: '0', margin: '0px', }}>
            {!this.props.isRoot &&
              <>
                <input type="checkbox" onChange={(e) => this.setState({ onlyFavourites: e.target.checked })} />
                <label>Favourites</label>
              </>
            }
            <input
              className="search-bar ignore-secret-input"
              type="search"
              placeholder="search farms..."
              value={this.state.search}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <span
              style={{
                cursor: "pointer",
                position: "absolute",
                marginLeft: "-26px",
                marginTop: "4px",
              }}
              onClick={() => this.setState({ search: '' })}
            >
              x
            </span>
          </div>
          <table id="farms" className={`${this.props.configData?.darkMode ? 'dark' : ''}`}>
            <thead>
              <tr>
                <th></th>
                <th style={{ padding: '8px 0px 8px 0px', width: '14%' }}>
                  <div className="thead-title">Farm<br />Name</div>
                  <SortColIcon colDir={this.state.selectedCol === 'name' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('name')} />
                </th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Number of cameras'>
                  <div className="thead-title">Cams</div>
                  <SortColIcon colDir={this.state.selectedCol === 'cameras' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('cameras')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Prodigy Version'>
                  <div className="thead-title">Prod<br />Ver</div>
                  <SortColIcon colDir={this.state.selectedCol === 'prodVer' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('prodVer')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Cow Vision Version'>
                  <div className="thead-title">CV<br />Ver</div>
                  <SortColIcon colDir={this.state.selectedCol === 'cvVer' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('cvVer')} /></th>
                {this.props.configData?.showHidden &&
                  <th style={{ padding: '8px 0px 8px 0px' }}>Farm ID</th>
                }
                {this.props.configData?.showHidden &&
                  <th style={{ padding: '8px 0px 8px 0px' }}>IP</th>
                }
                {this.props.configData?.showHidden &&
                  <th style={{ padding: '8px 0px 8px 0px' }}>EID Type</th>
                }
                <th style={{ padding: '8px 0px 8px 0px' }}>
                  <div className="thead-title">Cows</div>
                  <SortColIcon colDir={this.state.selectedCol === 'cowCount' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('cowCount')} /></th>
                <th style={{ padding: '8px 0px 8px 8px' }}>
                  <div className="thead-title">24Hr<br />Seen</div>
                  <SortColIcon colDir={this.state.selectedCol === 'cowReadings24Hr' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('cowReadings24Hr')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }}>
                  <div className="thead-title" title='Cows seen whose EID has been successfully matched'>24hr<br /> Matched</div>
                  <SortColIcon colDir={this.state.selectedCol === '24hr' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('24hr')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Cows seen where no EID has been matched'>
                  <div className="thead-title">Unmatched<br />EIDs</div>
                  <SortColIcon colDir={this.state.selectedCol === 'cowsUnmatched24Hr' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('cowsUnmatched24Hr')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title=''>
                  <div className="thead-title">Beef<br />Count</div>
                  <SortColIcon colDir={this.state.selectedCol === 'beefTwentyFourHourCowCount' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('beefTwentyFourHourCowCount')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Percentage of cows seen who have had a successfull EID match'>
                  <div className="thead-title">Matched<br />Percent</div>
                  <SortColIcon colDir={this.state.selectedCol === 'matchedPer' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('matchedPer')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Is the hard drive under 90% full'>
                  <div className="thead-title">HDD</div>
                  <SortColIcon colDir={this.state.selectedCol === 'hdd' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('hdd')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Is the camera connected to the VPN'>
                  <div className="thead-title">VPN</div>
                  <SortColIcon colDir={this.state.selectedCol === 'vpnConnected' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('vpnConnected')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='Is the camera connected to the API'>
                  <div className="thead-title">API</div>
                  <SortColIcon colDir={this.state.selectedCol === 'apiConnected' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('apiConnected')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='The last time the camera was pinged'>
                  <div className="thead-title">Last<br />Ping</div>
                  <SortColIcon colDir={this.state.selectedCol === 'lastPing' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('lastPing')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }} title='The last time the camera sent a reading from the farm'>
                  <div className="thead-title">Last<br />Reading</div>
                  <SortColIcon colDir={this.state.selectedCol === 'lastReading' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('lastReading')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }}>
                  <div className="thead-title" title='Active alarms on the farm'>Alarms</div>
                  <SortColIcon colDir={this.state.selectedCol === 'activeAlarms' ? (this.state.sortDirection) : ''} handleClick={() => this.handleColumnClick('activeAlarms')} /></th>
                <th style={{ padding: '8px 0px 8px 0px' }}>Install Status</th>
              </tr>
            </thead>
            <tbody>
              {allItemRows}
            </tbody>
          </table>
        </div >
      )
    } else {
      return <div>Loading....</div>
    }
  }
}

export default (LiveFarmsOverview)

/*
<tbody>
            {Object.entries(sortedFarmData).map(([key, fd]: [any, FarmData]) => {
              let reactSelectOptions = []
              if (farmIssues.selectOptions && fd.farm.issues) {
                reactSelectOptions = farmIssues.selectOptions.filter((issue: any) => {
                  return fd.farm.issues?.includes(issue.value)
                })
              }

              return (
                <tr style={fd.farm.issues?.length !== 0 || fd.bcsAlarmsToday <= fd.cowCount ? { backgroundColor: 'red' } : {}} key={fd.farm._id}>
                  <td>{fd.farm.name}</td>
                  {this.props.configData?.showHidden &&
                    <td>{fd.farm._id}</td>
                  }
                  {this.props.configData?.showHidden &&
                    <td><input type="text" name="test" defaultValue={fd.farm.ip} onChange={(e) => this.props.ipInputKeyPressed(fd.farm._id, e)} /></td>
                  }
                  {this.props.configData?.showHidden &&
                    <td>
                      <select onChange={(e) => this.props.setFarmEidType(e.target.value)}>
                        {farmEidTypes &&
                          farmEidTypes.selectOptions.map((eidType: any) => {
                            return <option key={eidType.value} value={fd.farm._id + '|' + eidType.value} selected={fd.farm.eidType === eidType.value}>{eidType.label}</option>
                          }
                          )}
                        {!farmEidTypes &&
                          <option>Loading....</option>
                        }
                      </select>
                    </td>
                  }
                  <td>{fd.cowCount}</td>
                  <td>{fd.bcsAlarmsToday}</td>
                  <td style={{ textAlign: 'center' }}>
                    {fd.farm.networkStatus?.vpnConnected === true && (
                      <FontAwesomeIcon icon={faCheck} size='2x' />
                    )}
                    {fd.farm.networkStatus?.vpnConnected === false && (
                      <FontAwesomeIcon icon={faTimes} size='2x' />
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {fd.farm.networkStatus?.apiConnected === true && (
                      <FontAwesomeIcon icon={faCheck} size='2x' />
                    )}
                    {fd.farm.networkStatus?.apiConnected === false && (
                      <FontAwesomeIcon icon={faTimes} size='2x' />
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {fd.lastPing && fd.lastPing.timestamp ? moment.utc(fd.lastPing?.timestamp).format('DD/MM/YY') : ''}
                    <br />
                    {fd.lastPing && fd.lastPing.timestamp ? moment.utc(fd.lastPing?.timestamp).format('HH:mm:ss') : ''}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {fd.farm.latestReading ? moment.utc(fd.farm.latestReading?.timestamp).format('DD/MM/YY') : ''}
                    <br />
                    {fd.farm.latestReading ? moment.utc(fd.farm.latestReading?.timestamp).format('HH:mm:ss') : ''}
                  </td>
                  <td>{fd.farm.activeAlarms}</td>
                  <td>
                    <select onChange={(e) => this.props.setFarmStatus(e.target.value)}>
                      {farmStatuses &&
                        farmStatuses.selectOptions.map((status: any) => {
                          return <option key={status.value} value={fd.farm._id + '|' + status.value} selected={fd.farm.status === status.value}>{status.label}</option>
                        }
                        )}
                      {!farmStatuses &&
                        <option>Loading....</option>
                      }
                    </select>
                  </td>
                  <td>
                    {farmIssues &&
                      <Select
                        name={fd.farm._id}
                        isMulti
                        onChange={this.props.updateFarmIssues}
                        options={farmIssues.selectOptions}
                        value={reactSelectOptions}
                      />
                    }
                    {!farmIssues &&
                      <div>Loading....</div>
                    }
                  </td>
                </tr>
              )
            }
            )}
          </tbody>
          */